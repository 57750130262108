import React, { useState, useEffect } from 'react';
import {Tabs, Tab, Box, Typography, Button, Paper,Fade, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, TextField, MenuItem, Grid, IconButton, Tooltip, Modal, Switch, FormControlLabel, CircularProgress, useTheme } from '@mui/material';
import { Editor } from '@monaco-editor/react';
import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { defaultCode, languageIdMapping, languageMapping } from '../common/constants';
import { useNavigate, useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import { showAuth } from '../redux/authSlice';
import { formatDateTime, getToken } from '../common/utils'
import proxy from '../api/axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Helmet } from 'react-helmet';
// import ProblemHint from '../components/AI/ProblemHint';

const formatString = (str) => {
  return str
    .replace(/\n/g, '<br />')
    .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
};

const ProblemPage = ({problem, contestId=0, challenge=null, callBack=null, isAdmin = false}) => {
  const appTheme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storedLanguage = localStorage.getItem('lang');
  const loggedInUser = useSelector((state)=>state.user.userInfo);
  const isLoggedIn = useSelector((state)=>state.user.isLoggedIn);
  const { slug } = useParams();
  const [problemDetails, setProblemDetails] = useState(problem || null);
  const [code, setCode] = useState(defaultCode[storedLanguage || 'java']);
  const [language, setLanguage] = useState(storedLanguage || 'java');
  const [fontSize, setFontSize] = useState(13);
  const [theme, setTheme] = useState('vs-dark');
  const [openSettings, setOpenSettings] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [customInput, setCustomInput] = useState('');
  const [isCustomInputEnabled, setIsCustomInputEnabled] = useState(false);
  const [output, setOutput] = useState('');
  const [isCompiling, setIsCompiling] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [submissions, setSubmissions] = useState([]); 
  const [currentTab, setCurrentTab] = useState(0);
  const [submissionModalOpen, setsSubmissionModalOpen] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [copiedText, setCopiedText] = useState(null); // To track the copied text

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedText(text);
      setTimeout(() => setCopiedText(null), 2000); // Reset copied state after 2s
    });
  };

  // Add keydown event listener for Ctrl+Enter or Shift+Enter
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl+Enter or Shift+Enter
      if (event.shiftKey && event.key === 'Enter') {
        event.preventDefault(); // Prevent any default actions, if necessary
        handleRunCode();
      }
    };

    // Attach the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const handleSubmissionModalOpen = (submission) => {
    setSelectedSubmission(submission);
    setsSubmissionModalOpen(true);
  };

  const handleSubmissionModalClose = () => {
    setsSubmissionModalOpen(false);
    setSelectedSubmission(null);
  };

  const getStoredCode = (lang,problemDetails) => {
    return localStorage.getItem(`${contestId}-${problemDetails ? problemDetails.slug : slug}-code-${lang}`) || problemDetails?.wrapperCode[lang] || defaultCode[lang];
  };

  const loadContestProblem = (problem)=>{
    if(!problem) return;
    setCurrentTab(0);
    setProblemDetails(problem);
    setCode(getStoredCode(language,problem));
    fetchProblemSubsmission(problem._id,contestId);
  }

  useEffect(()=>  {
    loadContestProblem(problem);
  },[problem]);

  const fetchProblemSubsmission = async (problemId, contestId=null) => {
    try {
      if(contestId){
        const { data } = await proxy.get(`/problems/${problemId}/${contestId}/submissions`);
        setSubmissions(data);
      } else {
        const { data } = await proxy.get(`/problems/${problemId}/submissions`);
        setSubmissions(data);
      }
    } catch (err) {
      console.error("Error fetching submissions", err);
    }
  };

  useEffect(() => {
    setOutput('');
    if(problem){
      loadContestProblem(problem);
      return;
    }
    const fetchProblemDetails = async () => {
      try {
        const { data } = await proxy.get(`/problems${isAdmin ? '/admin' : ''}/${slug}`);
        setProblemDetails(data);
        setCode(getStoredCode(language, data));
        fetchProblemSubsmission(data._id);
      } catch (err) {
        toast.error('Problem does not exist');
        navigate('/')
        console.error("Error fetching problem details:", err);
      }
    };

    fetchProblemDetails();
  }, [slug]);

  useEffect(() => {
    setCode(getStoredCode(language, problemDetails)); 
  }, [language]);

  const handleRunCode = async () => {
    if(!isLoggedIn){
      dispatch(showAuth());
      return;
    }
    setOutput('');
    setError(null);
    setIsCompiling(true);
    try {
      const requestBody = {
        source_code: code,
        language_id: languageMapping[language],
        testCase: {
          input: isCustomInputEnabled ? customInput : problemDetails.testCases[0].input,
          output: isCustomInputEnabled ? null : problemDetails.testCases[0].output  
        }
      };

      const { data } = await proxy.post('/code/compile', requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setOutput(data.message);
      setError(data.status!=='Accepted');
    } catch (error) {
      console.error("Error:", error);
      setError("Error occurred while running the code.");
    } finally {
      setIsCompiling(false);
    }
  };

  const handleSubmitCode = async () => {
    if(!isLoggedIn){
      dispatch(showAuth());
      return;
    }
    setOutput('');
    setError(null);
    setIsSubmitting(true);

    try {
      const requestBody = {
        source_code: code,
        language_id: languageMapping[language],
        slug: problemDetails.slug,
        contestId,
        challenge
      };

      const { data } = await proxy.post('/code/submit', requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        }
      });

      setOutput(data.message);
      setError(data.status!=='Accepted');

      if(challenge==='75Hard'){
        callBack(data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
      await fetchProblemSubsmission(problemDetails._id, contestId);
      setCurrentTab(2);
    }
  };

  const handleCodeChange = (newCode) => {
    setCode(newCode || defaultCode[language]);
    localStorage.setItem(`${contestId}-${problemDetails.slug}-code-${language}`, newCode || defaultCode[language]); // Persist the code for the selected language
  };

  const handleResetCode = ()=> {
    setCode(problemDetails?.wrapperCode[language]);
    localStorage.setItem(`${contestId}-${problemDetails.slug}-code-${language}`, problemDetails?.wrapperCode[language]);
  }

  const handleSettingsOpen = () => setOpenSettings(true);
  const handleSettingsClose = () => setOpenSettings(false);
  const handleThemeChange = (event) => setTheme(event.target.value);
  const handleFontSizeChange = (event) => setFontSize(event.target.value);
  const toggleFullscreen = () => setIsFullscreen(!isFullscreen);
  const handleCustomInputToggle = () => setIsCustomInputEnabled(!isCustomInputEnabled);
  const handleTabChange = (event, newValue) => {
    if(newValue===1 || newValue===2){ // Submissons tab
      if(!isLoggedIn){
        dispatch(showAuth());
        return;
      }
    }
    setCurrentTab(newValue);
  };
  const copyToClipboard = () => {
    if (selectedSubmission && selectedSubmission.code) {
      navigator.clipboard.writeText(selectedSubmission.code)
        .then(() => {
          // You can show a success message if needed
          toast.success('Code copied to clipboard!');
        })
        .catch((error) => {
          toast.success('Error copying code:', error);
        });
    }
  };
  if (!problemDetails) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  const getYouTubeId = (url) => {
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };
  const handleRefreshSubmissions = async ()=>{
    await fetchProblemSubsmission(problemDetails._id, contestId);
  }
  const handleConfirmPublish = async (problemId) => {
    try {
      await proxy.put(`/problems/publish/${problemId}`);
      toast.success('Published successfully');
      navigate('/admin/manage-problems')
    } catch (error) {
      toast.alert('Error publishing problem');
      console.error(error);
    }
  };
  

  return (
    <Box sx={{ padding: '20px' }}>
      {
        loggedInUser?.role === 'admin' && !problemDetails.isPublished &&
      (
        <>
          <Typography variant="h5" sx={{ color: '#555' }}>
            {problemDetails?.displayOrder +'# Level -'+ problemDetails.level +' - '+ problemDetails.section +' - ('+ problemDetails.topics+')' }
          </Typography>    
          <Button onClick={()=>handleConfirmPublish(problemDetails._id)} size='small' color='success' variant='contained' sx={{float:'right', margin:'0'}}>Publish</Button>
        </>
      )
      }
      <Helmet>
        <title>{problemDetails.title}</title>
        <meta name="description" content={problemDetails.description}/>
      </Helmet>
      <Grid container spacing={2}>
        {!isFullscreen && (
          <Grid item xs={12} md={6}>
          <Paper sx={{ padding: '20px', height: 'calc(100vh - 100px)', borderRadius: '8px', boxShadow: 2 }}>
              <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
                <Tab label="Description" />
                <Tab label="Explanation" />
                <Tab label="Submission" />
                {/* <Tab label="AI Hints" /> */}
              </Tabs>
            <PerfectScrollbar style={{ height: '90%' }}>
              {/* Tabs for Problem Content */}
        
              {currentTab === 0 && (
                <Box sx={{ padding: 2 }}>
                  {/* Problem Title */}
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {problemDetails.title}
                    {loggedInUser?.role === "admin" && (
                      <IconButton style={{ marginTop: '-5px', float: 'right' }} color="primary" onClick={() => navigate(`/admin/edit-problem/${problemDetails.slug}`)}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </Typography>
        
                  {/* Row containing Difficulty Bar, Solved Status, and Success Ratio */}
                  <Box sx={{ display: 'flex', marginRight: 2, justifyContent: 'space-between', mb: 1 }}>
                    <DifficultyBar difficulty={problemDetails.difficulty} />
                    <SolvedStatus isSolved={problemDetails.isSolved} />
                  </Box>
        
                  {/* Success Ratio */}
                  {
                    contestId === 0 &&
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, borderBottom: '3px dotted lightblue', paddingBottom: '5px' }}>
                    <Typography variant="body2" sx={{ color: '#555', fontSize: '12px' }}>
                      Submissions: {problemDetails.successSubmission + problemDetails.failedSubmission}
                    </Typography>
                    {/* Success Ratio */}
                    <Typography variant="body2" sx={{ color: '#555', fontSize: '12px' }}>
                      Success rate: {problemDetails.successSubmission === 0 && problemDetails.failedSubmission === 0
                        ? 'N/A' 
                        : `${((problemDetails.successSubmission / (problemDetails.successSubmission + problemDetails.failedSubmission)) * 100).toFixed(2)}%`}
                    </Typography>
                    
                  </Box>
                  }
        
                  {/* Problem Description */}
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, fontSize:'15px', lineHeight: 1.3, whiteSpace: 'pre-wrap' }}
                    dangerouslySetInnerHTML={{ __html: problemDetails.description }}
                  />
        
                  <Divider sx={{ my: 2 }} />
        
                  {/* Constraints */}
                  <Typography variant="body" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Constraints
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mb: 2 }}
                    dangerouslySetInnerHTML={{ __html: formatString(problemDetails.constraints) }}
                  />
        
                  <Divider sx={{ my: 2 }} />
        
                  {/* Sample Test Cases */}
                  <Typography variant="body" sx={{ fontWeight: 'bold' }}>
                    Sample Test Cases
                  </Typography>
                  {problemDetails.testCases.map((sample, index) => (
                    <Box key={index} sx={{ mb: 2, mt:1 }}>
                      {/* Input Section */}
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="body2">
                        <strong>Input {index + 1}:</strong>
                        <Tooltip title={copiedText === sample.input ? 'Copied!' : 'Copy Input'}>
                          <IconButton
                            size="small"
                            onClick={() => handleCopy(sample.input)}
                            sx={{ ml: 1 }}
                          >
                            <ContentCopyIcon sx={{fontSize:'13px'}}/>
                          </IconButton>
                        </Tooltip>
                        <br />
                        <pre>
                          <span dangerouslySetInnerHTML={{ __html: sample.input }} />
                        </pre>
                      </Typography>
                      

                      {/* Output Section */}
                      <Typography variant="body2" sx={{mt:1}}>
                        <strong>Expected Output {index + 1}:</strong>
                        <Tooltip title={copiedText === sample.output ? 'Copied!' : 'Copy Output'}>
                          <IconButton
                            size="small"
                            onClick={() => handleCopy(sample.output)}
                            sx={{ ml: 1 }}
                          >
                            <ContentCopyIcon sx={{fontSize:'13px'}}/>
                          </IconButton>
                        </Tooltip>
                        <br />
                        <pre>
                          <span dangerouslySetInnerHTML={{ __html: sample.output }} />
                        </pre>
                      </Typography>
                      
                      {sample.explanation &&
                      <Typography variant="body2" sx={{mt:1}}>
                        <strong>Explanation {index + 1}:</strong>
                        <br />
                        <pre>
                          <span dangerouslySetInnerHTML={{ __html: sample.explanation }} />
                        </pre>
                      </Typography>}
                    </Box>
                  ))}
                </Box>
              )}
        
              {currentTab === 1 && !contestId && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', overflow: 'hidden' }}>
                  {problemDetails.videoLink ? (
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                        paddingTop: '56.25%', // 16:9 Aspect Ratio
                      }}
                    >
                      <iframe
                        src={`https://www.youtube.com/embed/${getYouTubeId(problemDetails.videoLink)}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body1">No explanation available.</Typography>
                  )}
                </Box>
              )}
        
              {currentTab === 2 && (
                <Box sx={{ padding: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                  <Tooltip title="Refresh Submissions">
                    <IconButton onClick={handleRefreshSubmissions}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                
                {submissions.length > 0 ? (
                  <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: '8px' }}>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976d2' }}>
                          <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>#</TableCell>
                          <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>Submission Time</TableCell>
                          <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>Result</TableCell>
                          <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {submissions.map((submission, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:nth-of-type(odd)': { backgroundColor: appTheme.palette.mode === 'dark' ? '#333' : '#fff' },
                              '&:nth-of-type(even)': { backgroundColor: appTheme.palette.mode === 'dark' ? '#333' : '#fff' },
                            }}
                          >
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px', padding: '10px' }}>{submissions.length - index}</TableCell>
                            <TableCell sx={{ fontSize: '12px', padding: '10px' }}>{formatDateTime(submission.submissionTime).date + ' ' + formatDateTime(submission.submissionTime).time}</TableCell>
                            <TableCell sx={{ padding: '10px' }}>
                              <span
                                style={{
                                  color: submission.status === 'Accepted' ? 'green' : '#f72828', // Green for Accepted, Red for everything else
                                  backgroundColor: submission.status === 'Accepted' ? '#d4edda' : '#f8d7da', // Light green for Accepted, Light red for others
                                  padding: '4px 8px', // Padding for visual separation
                                  borderRadius: '4px', // Rounded corners for all statuses
                                  fontSize: '13px'
                                }}
                                dangerouslySetInnerHTML={{ __html: formatString(submission.status) }}
                              />
                            </TableCell>
              
                            <TableCell sx={{ padding: '10px' }}>
                              <Button sx={{ fontSize: '11px' }} variant="outlined" color="primary" size="small" onClick={() => handleSubmissionModalOpen(submission)}>
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body2" sx={{ textAlign: 'center', color: '#999' }}>
                    No previous submissions available.
                  </Typography>
                )}
                
                {/* Modal for viewing code */}
                <Modal
                  open={submissionModalOpen}
                  onClose={handleSubmissionModalClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={submissionModalOpen}>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '900px',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        maxHeight: '80vh',  // Limit the height of the modal
                        overflowY: 'auto',   // Enable scroll when content overflows
                      }}
                    >
                      {/* Close icon at the top right */}
                      <IconButton
                        onClick={handleSubmissionModalClose}
                        sx={{
                          position: 'absolute',
                          top: '16px',
                          right: '16px',
                          color: 'grey.500', // Adjust color as needed
                        }}
                      >
                        <CloseIcon />
                      </IconButton>

                      {selectedSubmission && (
                        <>
                          <Typography variant="body1" sx={{ mb: 1 }}>
                            <strong>Submitted Code</strong>
                          </Typography>

                          {/* Move the copy button above the code */}
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ mb: 1 }}
                            onClick={copyToClipboard}
                          >
                            Copy Code
                          </Button>
                          <Editor
                            height="300px"
                            language={languageIdMapping[selectedSubmission.languageId]}
                            value={selectedSubmission.code}
                            options={{
                              readOnly: true, // Makes the editor read-only
                              theme: 'dark',
                            }}
                          />

                          <Typography variant="body2" sx={{ mb: 2, mt: 1 }}>
                            <strong>Result:</strong>
                            <pre>
                              <span dangerouslySetInnerHTML={{ __html: formatString(selectedSubmission.submissionResult) }} />
                            </pre>
                          </Typography>
                        </>
                      )}

                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 2, display: 'block', ml: 'auto' }}  // Align close button to the right
                        onClick={handleSubmissionModalClose}
                      >
                        Close
                      </Button>
                    </Box>
                  </Fade>
                </Modal>

              </Box>
              
              )}

              {/* {
                currentTab === 3 && (
                  <ProblemHint problemDetails={problemDetails}/>
                )
              } */}
            </PerfectScrollbar>
          </Paper>
        </Grid>
        
        )}

        <Grid item xs={12} md={isFullscreen ? 12 : 6}>
          <Paper sx={{ padding: '10px', position: 'relative' }}>
            
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.3 }}>
                <TextField
                  select
                  label="Lang"
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.target.value);
                    setCode(problemDetails.wrapperCode[e.target.value] || defaultCode[e.target.value]);
                    localStorage.setItem('lang',e.target.value);
                  }}
                  sx={{ height: '30px', marginRight: '10px' }}
                  InputProps={{
                    style: { height: '30px' },
                  }}
                >
                  <MenuItem value="java">Java</MenuItem>
                  <MenuItem value="python">Py</MenuItem>
                  <MenuItem value="cpp">C++</MenuItem>
                  <MenuItem value="javascript">JS (Node)</MenuItem>
                  <MenuItem value="csharp">C#</MenuItem>
                </TextField>

                <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
                  {/* Fullscreen, refresh and Settings Icons */}
                  <Tooltip title="Reset Code">
                    <IconButton onClick={handleResetCode} sx={{ marginRight: '10px' }}>
                      <RefreshIcon/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Settings">
                    <IconButton onClick={handleSettingsOpen}>
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={isFullscreen ? 'Exit from Full Screen' : 'Switch to Full Screen' }>
                    <IconButton onClick={toggleFullscreen} sx={{ marginRight: '10px' }}>
                      {isFullscreen ? <CloseFullscreenIcon /> : <FullscreenIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <PerfectScrollbar style={{ height: '100%' }}>
              <Editor
                height="calc(99vh - 280px)"
                width="97%"
                language={language}
                value={code}
                onChange={handleCodeChange}
                options={{
                  selectOnLineNumbers: true,
                  theme: theme,
                  fontSize: fontSize,
                }}
              />

              {/* Buttons placed in a new row */}
              <Box sx={{ width:'97%', display: 'flex', justifyContent: 'space-between', mt: 2, ml:1.3 }}>
                <Box>
                  <FormControlLabel
                    control={<Switch checked={isCustomInputEnabled} onChange={handleCustomInputToggle} />}
                    label="Custom Input"
                  />
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleRunCode} disabled={isCompiling || isSubmitting} startIcon={ !isCompiling && <PlayArrowIcon />}>
                    {isCompiling ? <><CircularProgress style={{ marginRight: 10 }} size={20} color="inherit" /> {' Running'} </> : 'Run'}
                  </Button>
                  
                  <Button variant="contained" color="success" onClick={handleSubmitCode} disabled={isSubmitting || isCompiling} startIcon={!isSubmitting && <CheckCircleIcon />}>
                  {isSubmitting ? <><CircularProgress style={{ marginRight: 10 }} size={20} color="inherit" /> {' Submitting'} </> : 'Submit'}
                  </Button>
                </Box>
              </Box>

              {/* Custom Input Field */}
              {isCustomInputEnabled && (
                <TextField
                  label="Custom Input (stdin)"
                  multiline
                  minRows={3}
                  value={customInput}
                  onChange={(e) => setCustomInput(e.target.value)}
                  sx={{ width: '97%', mt: 2 }}
                  variant="outlined"
                />
              )}

              {output && (
                <Paper
                  sx={{
                    padding: '10px',
                    marginTop: '10px',
                    backgroundColor: error ? (appTheme.palette.mode=='dark' ? '#333' : '#881c1c') : ('#03523b'),
                    color: 'white',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <Typography variant="body1">{output}</Typography>
                </Paper>
              )}
            </PerfectScrollbar>
          </Paper>
        </Grid>
      </Grid>

      <Modal open={openSettings} onClose={handleSettingsClose}>
        <Box sx={{ backgroundColor: appTheme.palette.mode === 'dark' ? '#333' : '#fff',  padding: '20px', borderRadius: '8px', boxShadow: 3, width: 400, margin: 'auto', marginTop: '100px' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
            Editor Settings
          </Typography>
          <TextField
            select
            label="Theme"
            value={theme}
            onChange={handleThemeChange}
            sx={{ mb: 2, width: '100%' }}
          >
            <MenuItem value="vs-dark">Dark</MenuItem>
            <MenuItem value="vs-light">Light</MenuItem>
          </TextField>
          <TextField
            select
            label="Font Size"
            value={fontSize}
            onChange={handleFontSizeChange}
            sx={{ mb: 2, width: '100%' }}
          >
            {[12, 13, 14, 16, 18, 20].map(size => (
              <MenuItem key={size} value={size}>{size}px</MenuItem>
            ))}
          </TextField>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProblemPage;



// Function to return difficulty with a custom styled bar
const DifficultyBar = ({ difficulty }) => {
  const difficultyColors = {
    easy: '#4CAF50',   // Green for easy
    medium: '#FF9800', // Orange for medium
    hard: '#F44336'    // Red for hard
  };

  const difficultyIcons = {
    easy: '🟢',
    medium: '🟠',
    hard: '🔴'
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Box
        sx={{
          backgroundColor: difficultyColors[difficulty],
          width: '90px',
          height: '8px',
          borderRadius: '4px',
          mr: 2
        }}
      />
      <Typography variant="body2" sx={{ fontWeight: 'bold', color: difficultyColors[difficulty] }}>
        {difficultyIcons[difficulty]} {difficulty.charAt(0).toUpperCase() + difficulty.slice(1)}
      </Typography>
    </Box>
  );
};

// Function to show custom solved/unsolved status
export const SolvedStatus = ({ isSolved }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      {isSolved  &&
      <>
        <CheckCircleIcon sx={{ color: '#4CAF50', fontSize: `15`, mr: 0.4 }} />
        <Typography variant="body2" sx={{ fontWeight: 'bold', color: isSolved ? '#4CAF50' : '#F44336' }}>
          Solved
        </Typography>
      </>
      }
    </Box>
  );
};
