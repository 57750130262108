import React, { useEffect, useState, useRef } from 'react';
import {Dropdown, Button, Layout, Row, Col, Typography, Space, Select, message, Modal, Input, Switch, Menu } from 'antd';
import { CloseOutlined, PlayCircleOutlined, ShareAltOutlined, CopyOutlined } from '@ant-design/icons';
import { AudioMutedOutlined, AudioOutlined, VideoCameraOutlined, VideoCameraAddOutlined, SettingOutlined } from '@ant-design/icons';
import MonacoEditor from '@monaco-editor/react';
import proxy from '../../api/axios';
import { defaultCode, languageMapping } from '../../common/constants';
import { io } from 'socket.io-client';
import { useNavigate, useParams } from 'react-router-dom';
import { generateRandomString } from '../../common/utils';
import { Helmet } from 'react-helmet';
import { TeamOutlined, BgColorsOutlined } from '@ant-design/icons';
import * as bodyPix from '@tensorflow-models/body-pix';
import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-wasm';
import * as tf from '@tensorflow/tfjs';

const loadBodyPixModel = async () => {
  await tf.ready(); // Ensure TensorFlow.js is initialized
  await tf.setBackend('webgl'); // Use WebGL backend
  const net = await bodyPix.load(); // Load BodyPix model
  console.log('BodyPix model loaded');
  return net;
};

loadBodyPixModel().catch((err) => console.error('Error loading BodyPix model:', err));


const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const socket = io(window.location.origin.includes('localhost') ? 'http://localhost:5000' : 'https://crafterhack.com');

const LiveCodingRoom = () => {
  const navigate = useNavigate();
  const { roomId } = useParams();
  const [videoVisible, setVideoVisible] = useState(true);
  const [language, setLanguage] = useState('java');
  const [code, setCode] = useState(defaultCode[language]);
  const [fontSize, setFontSize] = useState(14);
  const [theme, setTheme] = useState('vs-dark');
  const [output, setOutput] = useState('');
  const [isCompiling, setIsCompiling] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const canvasRef = useRef(null);
  const localVideoRef = useRef(null); // Ref for the local video stream
  const remoteVideoRef = useRef(null); // Ref for the remote video stream
  const peerConnection = useRef(null); // Ref for the WebRTC PeerConnection
  const localStream = useRef(null); // Ref for the local media stream

  const [isMuted, setIsMuted] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [isRemoteMuted, setIsRemoteMuted] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);
  const [roomSize, setRoomSize] = useState(0);


  const toggleVideos = () => {
    setVideoVisible(!videoVisible);
  };

  // Load BodyPix for background segmentation
  useEffect(() => {
    const loadBodyPix = async () => {
      if (isBlurred && localVideoRef.current) {
        const net = await bodyPix.load();
        const stream = localVideoRef.current.srcObject;

        const videoTrack = stream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();
        const videoWidth = settings.width || 640;
        const videoHeight = settings.height || 480;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = videoWidth;
        canvas.height = videoHeight;

        const processFrame = async () => {
          const segmentation = await net.segmentPerson(localVideoRef.current);
          ctx.drawImage(localVideoRef.current, 0, 0, videoWidth, videoHeight);

          // Blur the background
          ctx.globalCompositeOperation = 'destination-in';
          bodyPix.drawMask(
            canvas,
            localVideoRef.current,
            bodyPix.toMask(segmentation, { r: 0, g: 0, b: 0, a: 0 }, { r: 0, g: 0, b: 0, a: 255 }),
            1,
            5
          );

          if (isBlurred) {
            requestAnimationFrame(processFrame);
          }
        };

        processFrame();
      }
    };

    loadBodyPix();
  }, [isBlurred]);

  const joinRoom = () => {
    const roomId = generateRandomString(7);
    console.log('Room created', roomId);
    navigate(`${roomId}`);
  };

  const initializeSocketConnection = () => {
    if (roomId) {
      socket.emit('join_room', roomId);

      // socket.on('room_not_found', (response) => {
      //   message.error(response.message);
      //   navigate('/live-coding-room');
      //   return;
      // });

      socket.on('room_full', (response) => {
        message.error(response.message);
        navigate('/live-coding-room');
        return;
      });
      socket.on('joined_room', (response) => {
        message.success(response.message);
        setRoomSize(response.size);
        return;
      });
      socket.on('room_size', (size) => {
        setRoomSize(size);
      });

      socket.on('code_update', (updatedCode) => {
        setCode(updatedCode);
      });

      socket.on('initializeCode', (initialCode) => {
        setCode(initialCode);
      });

      socket.on('offer', async (offer) => {
        if (!peerConnection.current) return;
        await peerConnection.current.setRemoteDescription(offer);
        const answer = await peerConnection.current.createAnswer();
        await peerConnection.current.setLocalDescription(answer);
        socket.emit('answer', { roomId, answer });
      });

      socket.on('answer', async (answer) => {
        if (!peerConnection.current) return;
        await peerConnection.current.setRemoteDescription(answer);
      });

      socket.on('ice-candidate', async (candidate) => {
        if (!peerConnection.current) return;
        await peerConnection.current.addIceCandidate(candidate);
      });

      socket.on('isMuted', (isMuted) => {
        setIsRemoteMuted(isMuted);
      });

      socket.on('user_left', (response) => {
        message.info(response.message);  // Show message when a user leaves
      });

      socket.on('connect_error', (error) => {
        console.error('Connection Error:', error);
        message.error('Failed to connect to the server');
      });

      socket.on('connect_timeout', () => {
        console.error('Connection Timeout');
        message.error('Connection Timeout');
      });
    }
  };

  const startVideoStream = async () => {
    try {
      localStream.current = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = localStream.current;
      }

      // Create the PeerConnection and set up handlers
      peerConnection.current = new RTCPeerConnection();

      localStream.current.getTracks().forEach((track) => {
        peerConnection.current.addTrack(track, localStream.current);
      });

      peerConnection.current.ontrack = (event) => {
        const [stream] = event.streams;
        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = stream;
        }
      };

      peerConnection.current.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit('ice-candidate', { roomId, candidate: event.candidate });
        }
      };

      // Create and send offer
      const offer = await peerConnection.current.createOffer();
      await peerConnection.current.setLocalDescription(offer);
      socket.emit('offer', { roomId, offer });
    } catch (error) {
      console.error('Error starting video stream:', error);
    }
  };

  useEffect(() => {
    initializeSocketConnection();
    startVideoStream();

    return () => {
      if (peerConnection.current) {
        peerConnection.current.close();
        peerConnection.current = null;
      }
      if (localStream.current) {
        localStream.current.getTracks().forEach((track) => track.stop());
        localStream.current = null;
      }
    };
  }, [roomId]);

  const handleEditorChange = (value) => {
    setCode(value || '');
    socket.emit('code_update', { roomId, code: value });
  };

  const handleRunCode = async () => {
    setOutput('');
    setIsCompiling(true);
    try {
      const requestBody = {
        source_code: code,
        language_id: languageMapping[language],
      };

      const { data } = await proxy.post('/code/compile', requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setOutput(data.message);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsCompiling(false);
    }
  };


  const handleShare = () => {
    setIsModalVisible(true);
  };

  const handleCopy = () => {
    const shareLink = `${window.location.origin}/live-coding-room/${roomId}`;
    navigator.clipboard.writeText(shareLink);
    message.success('Link copied to clipboard!');
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const toggleMute = () => {
    if (localVideoRef.current) {
      // Toggle the audio tracks
      localVideoRef.current.srcObject.getAudioTracks().forEach((track) => {
        track.enabled = !isMuted;
      });
  
      // Toggle the mute state
      setIsMuted((prev) => {
        const newMuteState = !prev;
        // Display message after the state has been toggled
        message.success(newMuteState ? 'Muted' : 'Unmuted');
        return newMuteState;
      });

      socket.emit('toggle_mute', { roomId, isMuted: !isMuted });
    }
  };
  
// Toggle video on/off
const toggleVideo = () => {
  if (localVideoRef.current) {
    localVideoRef.current.srcObject.getVideoTracks().forEach((track) => {
      track.enabled = !videoEnabled;
    });
    setVideoEnabled(!videoEnabled);
    message.success(videoEnabled ? 'Video Off' : 'Video On');
  }
};

// Toggle blur background
const toggleBlur = () => {
  setIsBlurred(!isBlurred);
  if (!isBlurred) {
    message.success('Background blurred');
  } else {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    message.success('Blur removed');
  }
};

  // Replace background (you can implement a complex background change logic here)
  const changeBackground = (color) => {
    if (localVideoRef.current) {
      localVideoRef.current.style.backgroundColor = color;
      message.success(`Background changed to ${color}`);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => changeBackground('#f0f2f5')}>Default</Menu.Item>
      <Menu.Item onClick={() => changeBackground('#ff4d4f')}>Red</Menu.Item>
      <Menu.Item onClick={() => changeBackground('#1890ff')}>Blue</Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Helmet>
        <title>Live Coding Room | Code Share | Compiler | CrafterHack</title>
        <meta
          name="description"
          content="This is the page for sharing the live code with others in real time and compiling the code in JavaScript, Java, Python, or C++."
        />
      </Helmet>
      <Header
        style={{
          backgroundColor: '#001529',
          padding: '10px 20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Title level={3} style={{ color: 'white', margin: 0 }}>
          Live Coding Room - [beta]
        </Title>

        {!roomId && (
          <Button type="primary"  onClick={joinRoom} style={{ marginLeft: 'auto', marginTop: '5px' }}>
            Start Sharing
          </Button>
        )}
        {roomId && (
          <Button type="primary" icon={<TeamOutlined />} onClick={handleShare} style={{ marginLeft: 'auto', marginTop: '5px' }}>
            Invite
          </Button>
        )}
      </Header>
      <Content style={{ padding: '20px', backgroundColor: '#f0f2f5' }}>
        <Row gutter={16} style={{ minHeight: '80vh' }}>

        {videoVisible && (
  <Col xs={24} sm={24} md={5} lg={5} xl={5}>
    <div
      className="video-container"
      style={{
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        backgroundColor: '#fff',
        padding: '10px',
        position: 'relative', // Ensure relative positioning for containing elements
        maxWidth: '100%', // Prevent overflow beyond parent
        height: 'auto', // Adjust height based on content
        overflow: 'hidden', // Ensure no content spills out
      }}
    >
      <Title level={5}>Faces</Title>
      <video
        ref={localVideoRef}
        autoPlay
        muted
        style={{
          width: '100%',
          height: 'auto', // Maintain aspect ratio
          transform: 'scaleX(-1)',
        }}
      />
      {isBlurred && (
        <canvas
          ref={canvasRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'auto', // Match the video
          }}
        />
      )}
      <Space
        style={{
          position: 'relative',
          bottom: '40px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 10,
        }}
      >
        <Button
          type="primary"
          icon={isMuted ? <AudioMutedOutlined /> : <AudioOutlined />}
          onClick={toggleMute}
          shape="circle"
        />
        <Button
          type="primary"
          icon={videoEnabled ? <VideoCameraOutlined /> : <VideoCameraAddOutlined />}
          onClick={toggleVideo}
          shape="circle"
        />
        <Button
          type="default"
          icon={<BgColorsOutlined />}
          onClick={toggleBlur}
          shape="circle"
        />
        <Dropdown overlay={menu} trigger={['click']}>
          <Button icon={<SettingOutlined />} shape="circle" />
        </Dropdown>
      </Space>

      <video
        ref={remoteVideoRef}
        autoPlay
        style={{
          width: '100%',
          height: 'auto',
          transform: 'scaleX(-1)',
        }}
      />
      {roomSize === 2 && remoteVideoRef.current && (
        <Button
          style={{ position: 'relative', bottom: '40px', left: '10px' }}
          type="default"
          icon={isRemoteMuted ? <AudioMutedOutlined /> : <AudioOutlined />}
          shape="circle"
        />
      )}
    </div>
  </Col>
)}


          <Col xs={24} sm={24} md={videoVisible ? 19 : 24} lg={videoVisible ? 19 : 24} xl={videoVisible ? 19 : 24}>
            <div
              className="editor-container"
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                backgroundColor: '#fff',
                height: '100%',
                padding: '10px',
              }}
            >
              <Space
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Space>
                  <Select
                    value={language}
                    onChange={(value) => setLanguage(value)}
                    style={{ width: 120 }}
                  >
                    <Option value="javascript">JavaScript</Option>
                    <Option value="java">Java</Option>
                    <Option value="python">Python</Option>
                    <Option value="cpp">C++</Option>
                    <Option value="csharp">C#</Option>
                  </Select>
                  <Select
                    value={fontSize}
                    onChange={(value) => setFontSize(value)}
                    style={{ width: 120 }}
                  >
                    <Option value={12}>12px</Option>
                    <Option value={14}>14px</Option>
                    <Option value={16}>16px</Option>
                    <Option value={18}>18px</Option>
                  </Select>
                  <Select
                    value={theme}
                    onChange={(value) => setTheme(value)}
                    style={{ width: 120 }}
                  >
                    <Option value="vs-dark">Dark</Option>
                    <Option value="light">Light</Option>
                  </Select>
                </Space>
                <Button
                  type="primary"
                  icon={<PlayCircleOutlined />}
                  onClick={handleRunCode}
                >
                  {isCompiling ? 'Running...' : 'Run Code'}
                </Button>
              </Space>

              <MonacoEditor
                height="500px"
                language={language}
                theme={theme}
                value={code}
                onChange={handleEditorChange}
                options={{
                  fontSize: fontSize,
                  minimap: { enabled: true },
                  automaticLayout: true,
                }}
              />

              {(output || isCompiling) && (
                <div
                  className="output-container"
                  style={{
                    marginTop: '10px',
                    padding: '10px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#f7f7f7',
                  }}
                >
                  <Title level={5}>Output</Title>
                  <Text>{output || 'Executing...'}</Text>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Row justify="center" style={{ marginTop: '20px' }}>
          <Space>
            {/* <Button type="primary" icon={<VideoCameraOutlined />} onClick={toggleVideos}>
              {videoVisible ? 'Hide Video' : 'Show Video'}
            </Button>
            <Button type="default" onClick={() => alert('Code saved successfully!')}>
              Save Code
            </Button> */}
            <Button type="default" onClick={()=>navigate('/')} danger icon={<CloseOutlined />}>
              End Session
            </Button>
          </Space>
        </Row>
      </Content>

      <Modal
        title="Share Live Coding Room"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {/* Instructional Text */}
          <Typography.Paragraph>
            Share this link with others to collaborate on coding in real-time. If you'd like others to view only, toggle the "View Only" option below.
          </Typography.Paragraph>

          {/* Input for Sharing Link */}
          <Input
            value={`${window.location.origin}/live-coding-room/${roomId}`}
            readOnly
            addonAfter={<CopyOutlined onClick={handleCopy} style={{ cursor: 'pointer' }} />}
          />

          {/* Toggle for View-Only Option */}
          <Space style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
            <Text strong>View Only:</Text>
            <Switch checked={isViewOnly} onChange={setIsViewOnly} />
          </Space>

          {/* Additional Note */}
          <Typography.Text type="secondary">
            Note: When "View Only" is enabled, participants can only view the code but cannot edit it.
          </Typography.Text>
        </Space>
      </Modal>

    </Layout>
  );
};

export default LiveCodingRoom;
